console.log('JS OK');


// DIAPORAMA SIMPLESLIDER
var sliderHome = document.querySelector(".simpleSlider");
var isNext = true;

function displayLegende(){
    var slideLegende = document.querySelectorAll(".legende");
    Array.prototype.forEach.call(slideLegende, function(el, i){
      el.classList.add("show");
    });
};

var enfantSlide = sliderHome.querySelectorAll("article");
var imgSlider = simpleslider.getSlider({
  children: enfantSlide,
  paused: false,
  prop: 'opacity',
  delay: 5,
  unit: '',
  init: 0,
  show: 1,
  end: 0,
  onChange: displayLegende
});
displayLegende();